<template>
  <div v-if="tags.length > 0" class="tag-cloud">
    <h3>Tags</h3>
    <div class="row">
      <input type="text" placeholder="Search..." v-model="searchQuery" />
    </div>
    <div
      v-for="tag in searchTags"
      :key="tag"
      class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5"
    >
      <router-link :to="{ name: 'OfferingsTag', params: { tag: tag } }">
        #{{ tag }}
      </router-link>
    </div>
  </div>
</template>

<script>
import useTags from "@/composables/useTags";
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";

export default {
  props: ["offerings"],
  setup(props) {
    const searchQuery = ref("");
    const { tags } = useTags(props.offerings);

    const searchTags = computed(() => {
      return tags.value.filter(tag => {
        return tag.toLowerCase().indexOf(searchQuery.value.toLowerCase()) != -1;
      });
    });

    return { searchQuery, searchTags, tags };
  }
};
</script>

<style>
.tag-cloud {
  padding: 2px;
}
.tag-cloud h3 {
  border-bottom: 1px solid #eee;
  padding: 16px 8px;
  color: #444;
}
.tag-cloud div {
  display: inline-block;
  padding: 10px;
}
.tag-cloud a {
  text-decoration: none;
}
.tag-cloud a.router-link-active {
  font-weight: bold;
}
</style>