import { ref } from 'vue';

const useTags = (offerings) => {
    const tags = ref([]);
    const tagSet = new Set();

    offerings.forEach(item => {
        item.tags.forEach(tag => tagSet.add(tag));
    });

    tags.value = [...tagSet];
    tags.value.sort();
    return { tags };
};

export default useTags;