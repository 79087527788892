<template>
  <main>
    <div v-if="offerings" class="container page-content">
      <router-link
        :to="{ name: 'CreateOffering' }"
        class="btn btn-primary float-end"
        >Create Offering</router-link
      >
      <h3>#{{ route.params.tag }} Offerings</h3>
      <div class="row align-items-start">
        <div class="col-12 col-lg-9">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
            <div
              v-for="offering in offeringsWithTag"
              :key="offering.id"
              class="col"
            >
              <OfferingSingle :offering="offering" />
            </div>
            <div class="text-center" v-if="offeringsWithTag.length == 0">
              <p>No offerings exist with this tag.</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <TagCloud :offerings="offerings" />
        </div>
      </div>
    </div>
  </main>
  <Footer v-if="offerings" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import OfferingSingle from "@/components/offerings/OfferingSingle.vue";
import TagCloud from "@/components/tags/TagCloud.vue";
import getCollection from "@/composables/getCollection";
import getUser from "@/composables/getUser";
import { useRoute } from "vue-router";
import { computed } from "@vue/runtime-core";

export default {
  components: { OfferingSingle, Footer, TagCloud },
  setup() {
    const { user } = getUser();
    const route = useRoute();
    const { error, documents: offerings } = getCollection(
      "offerings",
      [["issuerId", "==", user.value.uid]],
      ["name", "asc"]
    );

    const offeringsWithTag = computed(() => {
      return offerings.value.filter(offering =>
        offering.tags.includes(route.params.tag)
      );
    });

    return { error, offerings, offeringsWithTag, route };
  }
};
</script>

<style scoped>
</style>